/* Main or Home page styles */

.main {
  padding-top: 15%;
}

.picture-frame {
  text-align: right;
  padding-bottom: 1em;
}

.profile-pic {
  width: 150px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  box-shadow: 0 0 8px 8px white inset;
  transition: all 0.5s ease;
}

.profile-pic:hover {
    filter: none;
    -webkit-filter: grayscale(0);
}

.name-header {
  font-family: 'Lora', serif;
  font-weight: 400;
  font-size: 2rem;
  overflow: hidden; 
  white-space: nowrap;
  padding-left: 1rem;
  margin-top: -.5rem;
  letter-spacing: .1rem;
}

.bio {
  padding: 0 1rem;
}
@media (max-width: 768px) {
  .picture-frame {
    text-align: center;
  }
  .name-header, .bio {
    margin: 0 auto;
  }
  .bio {
    padding: 2rem;
  }
}