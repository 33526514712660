body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

.elsie {
  font-family: 'Lora', serif;
  font-weight: 400;
  font-size: 1.4rem;
}