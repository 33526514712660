/* Nerdy Birdy game styles */

.game-header {
  font-size: 2rem;
  text-align: center;
}

#PhaserContainer {
  width: 800px;
  height: 600px;
  margin: 0 auto;
}

.game-footer {
  margin-top: 5px;
  font-size: .8rem;
}