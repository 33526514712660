/* Birdy styles */

.birdy {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.birdy-animate {
  transition: all 0.5s ease;
  animation: birdy-bounce infinite 4s linear;
  animation-delay: 5s;
}

.birdy-img {
  height: 30px;
  width: auto;
}

.birdy:hover {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -webkit-animation-play-state: paused;
     animation-play-state: paused;
}

.birdy-container {
  text-align: left;
}

.birdy-container:hover {
  cursor: pointer;
}

@keyframes birdy-bounce {
  0%   { transform: translateY(0); }
  10%  { transform: translateY(0); }
  20%  { transform: translateY(-40px) rotate(0deg); }
  30%  { transform: translateY(-40px) rotate(-360deg); }
  50%  { transform: translateY(-40px) rotate(-360deg);}
  60%  { transform: translateY(0) rotate(-360deg); }
  67%  { transform: translateY(-7px) rotate(-360deg); }
  74%  { transform: translateY(0) rotate(-360deg); }
  100% { transform: translateY(0) rotate(-360deg);}
}

@media (max-width: 1000px) {
  .birdy-container, .bubble {
    display: none;
  }
}

