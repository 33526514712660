/* Main App Styles */

.App {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
  height: 100%;
}

.links {
  color: #000000;
  border-bottom: 2px solid rgba(135, 222, 249, 0.9);
}

.links:hover {
  font-weight: bold;
  text-decoration: none;
  color: #404040;
}

.popover-text {
  text-align:center;
}