/* Footer styles */

.footer {
  font-size: .8rem;
  justify-content: center;
  align-content: flex-end;
  position: absolute;
  bottom: 0;
  padding-bottom: 10px;
}

.links-container {
  text-align: center;
  padding: 0;
  min-width: 200px;
}

.speech-bubble-hidden {
  opacity: 0;
}

.speech-bubble {
  position: relative;
  left: 15px;
  background: rgba(135, 222, 249, 0.7);
  border-radius: .4em;
  padding: .5rem 1rem;
  text-align: center;
  font-size: .8rem;
  opacity: 0;
  animation: fadeIn 2s;
  animation-delay: 5s;
  animation-fill-mode: forwards;
}

.speech-bubble:hover {
  cursor: pointer;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.speech-bubble:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
  border-right-color: rgba(135, 222, 249, 0.7);
  border-left: 0;
  margin-top: -0.5em;
  margin-left: -0.5em;
}

@media (min-width: 768px) {
  .links-container {text-align: right;}
}

@media (max-width: 450px) {
  .footer {
    position: relative;
    bottom: auto;
  }
}